export const EXPERIENCE_LEVEL_OPTIONS = [
  { value: 'No Level', label: 'No Level' },
  { value: 'Entry', label: 'Entry' },
  { value: 'Junior', label: 'Junior' },
  { value: 'Middle', label: 'Middle' },
  { value: 'Senior', label: 'Senior' },
  { value: 'Lead', label: 'Lead' },
  { value: 'Custom', label: 'Custom' },
];

export const SOCIAL_NETWORKS_OPTIONS = [
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'StackOverflow', label: 'StackOverflow' },
  { value: 'Gitlab', label: 'Gitlab' },
  { value: 'GitHub', label: 'GitHub' },
  { value: 'VK', label: 'VK' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Dribbble', label: 'Dribbble' },
  { value: 'Behance', label: 'Behance' },
  { value: 'Link', label: 'Link' },
];

export const LATIN_VALIDATION = {
  validator: (_, value) => {
    if (!value) return Promise.reject(new Error('This field is required'));
    if (/^[a-zA-Z\d\s-–—]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Only Latin characters, numbers, space, dash(minus) are allowed'));
  },
};
export const VALIDATION_MAX_100 = {
  validator: (_, value = []) => {
    if (value.find(v => v.value.length > 100)) return Promise.reject(new Error('Maximum 100 symbols allowed'));
    return Promise.resolve();
  },
};
export const PHONE_VALIDATOR = {
  validator: (_, value) => {
    if (!value || /^[+\d]+$/.test(value)) return Promise.resolve();
    return Promise.reject(new Error('Only plus and numbers are allowed'));
  },
};
export const EMAIL_VALIDATION = { type: 'email', message: 'Email error' };
export const REQUIRED_RELATED_FILLED_VALIDATOR = (name, field, form) => ({
  validator: (_, value) => {
    const fields = form.getFieldsValue();
    const relatedFieldValue = fields.experience[name][field];
    if ((!value || !value?.length) && relatedFieldValue?.length) {
      return Promise.reject(new Error('This field is required'));
    }
    return Promise.resolve();
  },
});
