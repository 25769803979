import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AtsSkeleton, Table } from '_atoms';
import { Typography } from '_atoms/Typography';
import { getCandidateJobs, getCandidateJobsLoading } from 'store/selectors/candidateSelectors';
import { ReactComponent as EmptyImage } from 'dist/emptyStates/dashboardOpening.svg';
import { useJobAssignedData } from './utils';
import { COLUMNS } from './constants';
import './style.scss';

export const JobAssignedTabContent = ({ isSSC, getJobs }) => {
  const { id } = useParams();

  const jobs = useJobAssignedData(useSelector(getCandidateJobs));
  const loading = useSelector(getCandidateJobsLoading);

  useEffect(() => {
    if (!isSSC) getJobs(id);
  }, []);

  if (loading)
    return (
      <div className="job-assigned__loading">
        <AtsSkeleton count={3} height={145} />
      </div>
    );
  if (!jobs.length)
    return (
      <div className="job-assigned__empty">
        <EmptyImage />
        <Typography.Text>Candidate is not assigned to any job yet</Typography.Text>
      </div>
    );

  return (
    <div className="job-assigned">
      <Table rowKey="id" columns={COLUMNS} data={jobs} showCardHeader={false} showCardFooter={false} />
      <JobAssignmentList data={jobs} />
    </div>
  );
};

const JobAssignmentList = ({ data }) => (
  <div className="job-assigned_mobile">
    {data.map(d => (
      <div className="assignment-item">
        {COLUMNS.map(c => (
          <div className="assignment-item__row">
            <Typography.Text className="title">{c.title}:</Typography.Text>
            <div className="content">{c.render?.(d[c.key]) || d[c.key]}</div>
          </div>
        ))}
      </div>
    ))}
  </div>
);
