import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Popup } from '_atoms';
import { BreadCrumbs } from '_atoms/BreadCrumbs';
import { JobHeader, PopupReassignRecruiter, StepsEmpty, PopupCloseDeclineOpening } from '_molecules';
import { JobStepsTab } from '_organisms';
import { JobDescriptionTab, JobOpeningsTab, JobHiredTab } from '_templates';
import { setStepsToJob } from 'store/reducers/jobReducer';
import { setLoading } from 'store/reducers/commonReducer';
import { getUser } from 'store/selectors/authorizeSelectors';
import { getHrManagers, getLocations, getStepsLoading } from 'store/selectors/commonSelectors';
import {
  getJobApprovers,
  getJobCandidateDataLoading,
  getJobCandidates,
  getJobCVS,
  getJobCVSLinks,
  getJobData,
  getJobInterview,
  getJobLoader,
  getJobOffering,
  getJobProbationPeriod,
  getJobScorecard,
  getJobSteps,
  getJobUserData,
  getOpeningCandidate,
  getOpeningsList,
  getRooms,
} from 'store/selectors/jobSelectors';
import history from '../../helpers/history';
import * as actions from '../../actions';
import { MixpanelService } from '../../helpers/Mixpanel';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from '../../constants/text';
import { JOB_STATUSES } from '../../constants/job';
import { AbilityContext, ACTIONS, SUBJECTS } from '../../permission';
import './jobs.scss';

class ViewJob extends Component {
  initialsState = {
    teamLead: '',
    name: '',
    confirmationPopup: false,
    service_info: null,
    openDeletePopup: false,
    openArchivePopup: false,
    openUnarchivePopup: false,
    selectedRR: {},
    selectedMR: [],
    modeApprovers: false,
    idToEdit: null,
    openDeclinePopup: undefined,
    last_closed_at: null,
    last_archived_at: null,
    disabled: false,
    confirmAssignToYou: false,
    recruiter: {},
    activeStep: 0,
    showRecruiters: false,
    isActiveRecruiter: false,
    isActiveRecruiterUuid: {},
    newRecruiters: [],
    confirmationDeletingRRPopup: false,
    disableOpeningActions: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialsState,
      componentDidSetProps: false,
    };
    this.showPopupDelete = this.showPopupDelete.bind(this);
    this.selectRR = this.selectRR.bind(this);
    this.approveRR = this.approveRR.bind(this);
    this.declineOpening = this.declineOpening.bind(this);
    this.approveDeclineOpening = this.approveDeclineOpening.bind(this);
    this.showDeclinePopup = this.showDeclinePopup.bind(this);
    this.selectMultipleRecruiters = this.selectMultipleRecruiters.bind(this);
    this.updateData = this.updateData.bind(this);
    this.approveMultipleRecruiters = this.approveMultipleRecruiters.bind(this);
    this.deleteRecruiter = this.deleteRecruiter.bind(this);
    this.reassignRecruiter = this.reassignRecruiter.bind(this);
    this.unassignRecruitiers = this.unassignRecruitiers.bind(this);
    this.showPopupArchive = this.showPopupArchive.bind(this);
    this.showPopupUnarchive = this.showPopupUnarchive.bind(this);
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.props.getJobInfo(this.props.id);
    if (this.props.tab === 'steps')
      this.props.getSteps(this.props.id).then(steps => {
        if (this.props.step || this.props.candidate) return;
        let needActionsStep = steps?.findIndex(step => step.need_actions);
        if (needActionsStep < 0) needActionsStep = 0;
        history.push(
          `/jobs/${this.props.id}/show/steps/${this.props.steps[needActionsStep].uuid}${
            this.props.candidate ? `/${this.props.candidate}` : ''
          }`
        );
      });
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Job Details', {
      'Job ID': this.props.id,
    });
  }

  componentWillUnmount() {
    this.props.setStepsToJob([]);
    this.props.clearJobInfo();
  }

  deleteRecruiter(item) {
    const newRecruiters = [...this.props.jobData.recruiter_leads, ...this.props.jobData.recruiters]
      .filter(i => i.uuid !== item.uuid)
      .map(i => i.uuid);
    if (item.is_active_responsible_recruiter) {
      this.setState({
        isActiveRecruiter: true,
        isActiveRecruiterUuid: { uuid: item.uuid, full_name: item.full_name, avatar: item.avatar },
      });
    } else {
      this.setState({ confirmationDeletingRRPopup: true });
      this.setState({ newRecruiters });
    }
  }

  unassignRecruitiers(reassignRecruiters) {
    this.props
      .reassignRecruiterWithActiveCandidates(
        {
          reassign_recruiters: Object.entries(reassignRecruiters).map(([key, v]) => ({
            old_recruiter: key,
            new_recruiter: v.value,
          })),
          job: this.props.jobData.uuid,
        },
        this.props.jobData.uuid
      )
      .then(() => {
        if (this.props.tab === 'steps') {
          this.props.getCandidateStepAssignment(this.props.id, this.props.candidate, false);
        }
      });
    this.setState({ isActiveRecruiter: false });
  }

  showPopupDelete(value) {
    this.setState({
      openDeletePopup: value,
    });
  }

  showPopupArchive(value) {
    this.setState({
      openArchivePopup: value,
    });
  }

  showPopupUnarchive(value) {
    this.setState({
      openUnarchivePopup: value,
    });
  }

  selectRR(item) {
    this.setState({
      selectedRR: item,
    });
  }

  selectMultipleRecruiters(item) {
    const { selectedMR } = this.state;
    const index = selectedMR.findIndex(el => el.uuid === item.uuid);
    if (item.length < 1) this.setState({ selectedMR: item });
    else if (index !== -1) this.setState({ selectedMR: [...selectedMR.filter(el => el.uuid !== item.uuid)] });
    else this.setState({ selectedMR: [...this.state.selectedMR, item] });
  }

  approveMultipleRecruiters() {
    const arr = [];
    this.props.jobData.recruiters.forEach(i => arr.push(i.uuid));
    this.state.selectedMR.forEach(i => !arr.includes(i.uuid) && arr.push(i.uuid));
    this.props.assignHrToJobs(this.props.jobData.uuid, arr);
  }

  reassignRecruiter(uuid) {
    const arr = [];
    this.state.selectedMR.forEach(i => arr.push(i.uuid));
    this.props.assignHrToJobs(this.props.jobData.uuid, arr);
  }

  approveRR(activeStep) {
    this.props
      .reassignResponsibleRecruiter(
        {
          candidate: this.props.userData.uuid,
          job: this.props.jobData.uuid,
          recruiter: this.state.selectedRR.uuid,
        },
        this.props.steps[activeStep].uuid,
        true
      )
      .then(() => {
        this.props.getCandidateStepAssignment(this.props.id, this.props.candidate, false);
      });
    this.setState({
      recruiter: this.state.selectedRR,
    });
  }

  declineOpening(reason) {
    this.props.approveDeclineOpening(
      this.props.id,
      this.state.idToEdit,
      'decline',
      { reason },
      this.state.openingsFilter
    );
    this.showDeclinePopup(false);
  }

  updateData(filed, value) {
    this.setState({ ...this.state, [filed]: value });
  }

  approveDeclineOpening(opening, isDecline, filters) {
    if (isDecline) {
      this.setState({ openingsFilter: filters });
      this.showDeclinePopup(opening);
    } else {
      if (this.state.disableOpeningActions) return;
      this.setState({ disableOpeningActions: true });
      this.props.approveDeclineOpening(this.props.id, opening.uuid, 'approve', {}, filters).finally(() => {
        this.props.getJobInfo(this.props.id).finally(() => {
          this.setState({ disableOpeningActions: false });
        });
      });
    }
  }

  showDeclinePopup(opening) {
    this.setState({
      openDeclinePopup: opening || undefined,
      idToEdit: opening ? opening.uuid : null,
    });
  }

  render() {
    const isArchived = this.props.jobData.status === JOB_STATUSES.ARCHIVED;
    const showAddOpening = !isArchived && this.context.can(ACTIONS.CREATE, SUBJECTS.OPENING);
    const showDelete =
      !this.props.steps.reduce((sum, { need_actions }) => sum + need_actions, 0) &&
      !this.props.openingsList.find(({ status }) => status !== JOB_STATUSES.ARCHIVED);
    const showArchive =
      this.props.jobData.status === JOB_STATUSES.OPEN &&
      !this.props.openingsList.find(({ status }) => status !== JOB_STATUSES.ARCHIVED);
    return (
      <>
        <div className="wrapper-content job-view">
          <BreadCrumbs element="Jobs" elementName={this.props.jobData.name} path="/jobs" />
          <JobHeader
            name={this.props.jobData.name}
            service={this.props.jobData.service}
            status={this.props.jobData.status}
            recruiters={this.props.jobData.recruiters || []}
            recruiterLeads={this.props.jobData.recruiter_leads || []}
            teamLead={this.props.jobData.team_lead}
            resourceManager={this.props.jobData.resource_manager}
            showAddOpening={showAddOpening}
            showArchive={showArchive}
            showDelete={showDelete}
            showPopupArchive={this.showPopupArchive}
            showPopupUnarchive={this.showPopupUnarchive}
            showPopupDelete={this.showPopupDelete}
            activeTab={this.props.tab}
            onTabClick={value => {
              if (value === 'steps' && this.props.tab !== 'steps') {
                this.props.getSteps(this.props.id).then(steps => {
                  let needActionsStep = steps?.findIndex(step => step.need_actions);
                  if (needActionsStep < 0) needActionsStep = 0;
                  history.push(`/jobs/${this.props.id}/show/steps/${this.props.steps[needActionsStep].uuid}`);
                });
              } else {
                history.push(`/jobs/${this.props.id}/show/${value}`);
              }
            }}
            disabled={this.state.disabled}
            deleteRecruiter={this.deleteRecruiter}
          />
          {this.props.jobData.is_assignments_exists === false && this.props.tab === 'steps' && (
            <StepsEmpty
              showPopupUnarchive={this.showPopupUnarchive}
              jobStatus={this.props.jobData.status}
              recruiters={this.props.jobData.recruiters || []}
              recruiterLeads={this.props.jobData.recruiter_leads || []}
            />
          )}
          {this.props.tab === 'steps' && !!this.props.steps.length && this.props.jobData.is_assignments_exists && (
            <JobStepsTab
              activeStep={this.props.step}
              loader={this.props.loader}
              steps_loading={this.props.steps_loading}
              candidateDataLoading={this.props.candidateDataLoading}
              activityList={this.props.activityList}
              selectRR={this.selectRR}
              recruiter={this.props.jobData.recruiter}
              selectedRR={this.state.selectedRR}
              jobData={this.props.jobData}
              approveRR={this.approveRR}
              steps={this.props.steps}
              userData={this.props.userData}
              getCandidateStepAssignment={this.props.getCandidateStepAssignment}
              candidates={this.props.candidates}
              candidate={this.props.candidate}
              id={this.props.id}
              getCandidatesJobs={this.props.getCandidatesJobs}
              getSteps={this.props.getSteps}
              approversCandidate={this.props.approversCandidate}
              cvs={this.props.cvs}
              cvsLinks={this.props.cvsLinks}
              author={this.props.author}
              scorecard={this.props.scorecard}
              openingsList={this.props.openingsList}
              interview={this.props.interview}
              offeringCandidate={this.props.offeringCandidate}
              probationPeriod={this.props.probationPeriod}
              getOpenings={body => this.props.getOpenings(this.props.id, body)}
              openingCandidate={this.props.openingCandidate}
              rooms={this.props.rooms}
              updateData={this.updateData}
              getActivityList={this.props.getActivityList}
            />
          )}
          {this.props.tab === 'description' && (
            <JobDescriptionTab
              createdAt={this.props.jobData.created_at}
              lastOpenedAt={this.props.jobData.last_opened_at}
              lastArchivedBy={this.props.jobData.last_archived_by}
              lastArchivedAt={this.props.jobData.last_archived_at}
              teamLead={this.props.jobData.team_lead}
              resourceManager={this.props.jobData.resource_manager}
              recruiters={this.props.jobData.recruiters || []}
              recruiterLeads={this.props.jobData.recruiter_leads || []}
              service={this.props.jobData.service}
              company={this.props.jobData.company || {}}
              description={this.props.jobData.description}
              benefits={this.props.jobData.benefits}
            />
          )}
          {this.props.tab === 'openings' && (
            <JobOpeningsTab
              showAddOpening={showAddOpening}
              openingsList={this.props.openingsList}
              approveDeclineOpening={this.approveDeclineOpening}
              getListLocations={this.props.getListLocations}
              jobName={this.props.jobData.name}
            />
          )}
          {this.props.tab === 'hired' && <JobHiredTab jobName={this.props.jobData.name} />}
        </div>
        <PopupReassignRecruiter
          open={this.state.isActiveRecruiter}
          onClose={() => this.setState({ isActiveRecruiter: false })}
          onConfirm={this.unassignRecruitiers}
          recruitersReassignFrom={[this.state.isActiveRecruiterUuid]}
        />
        <Popup
          open={this.state.confirmAssignToYou}
          title={POPUP_LABELS.ASSIGN_CANDIDATE}
          body={POPUP_BODY.ASSIGN_CANDIDATE}
          onClose={() => {
            this.setState({ confirmAssignToYou: false });
          }}
          closeTxt={BUTTON_TEXT.BACK}
          onConfirm={() => {
            const stepNumber = this.props.steps.findIndex(({ uuid }) => uuid === this.props.step);
            this.props.reassignResponsibleRecruiter(
              {
                candidate: this.props.userData.uuid,
                job: this.props.jobData.uuid,
                recruiter: this.props.author.uuid,
              },
              this.props.step,
              true,
              stepNumber
            );
            this.setState({ recruiter: this.props.author });
            this.setState({ confirmAssignToYou: false });
          }}
        />
        <Popup
          open={this.state.confirmationDeletingRRPopup}
          title={POPUP_LABELS.REMOVE_RECRUITER}
          cancelButtonProps={{
            onClick: () => this.setState({ confirmationDeletingRRPopup: false }),
            children: BUTTON_TEXT.CANCEL,
          }}
          okButtonProps={{
            onClick: () => {
              this.props.assignHrToJobs(this.props.jobData.uuid, this.state.newRecruiters);
              this.setState({ confirmationDeletingRRPopup: false });
            },
            children: BUTTON_TEXT.CONFIRM,
          }}
        >
          {POPUP_BODY.REMOVE_RECRUITER}
        </Popup>
        <Popup
          open={this.state.openDeletePopup}
          title={POPUP_LABELS.DELETE_JOB}
          cancelButtonProps={{ onClick: () => this.showPopupDelete(false), children: BUTTON_TEXT.CANCEL }}
          okButtonProps={{
            onClick: () => {
              this.props.deleteJobs(this.props.id);
              this.showPopupDelete(false);
            },
            children: 'Delete job',
          }}
        >
          {POPUP_BODY.DELETE_JOB}
        </Popup>
        <Popup
          open={this.state.openArchivePopup}
          title={POPUP_LABELS.ARCHIVE_JOB}
          cancelButtonProps={{ onClick: () => this.showPopupArchive(false), children: BUTTON_TEXT.CANCEL }}
          okButtonProps={{
            onClick: () => {
              this.showPopupArchive(false);
              this.props.changeStateJob(this.props.id, { status: JOB_STATUSES.ARCHIVED }).then(() => {
                this.props.getJobInfo(this.props.id);
              });
            },
            children: BUTTON_TEXT.ARCHIVE,
          }}
        >
          {POPUP_BODY.ARCHIVE_JOB}
        </Popup>
        <Popup
          open={this.state.openUnarchivePopup}
          title={POPUP_LABELS.UNARCHIVE_JOB}
          cancelButtonProps={{ onClick: () => this.showPopupUnarchive(false), children: BUTTON_TEXT.CANCEL }}
          okButtonProps={{
            onClick: () => {
              this.showPopupUnarchive(false);
              this.props.changeStateJob(this.props.id, { status: JOB_STATUSES.OPEN }).then(() => {
                this.props.getJobInfo(this.props.id);
              });
            },
            children: BUTTON_TEXT.UNARCHIVE,
          }}
        >
          {POPUP_BODY.UNARCHIVE_JOB}
        </Popup>
        <PopupCloseDeclineOpening
          open={this.state.openDeclinePopup}
          onClose={() => this.showDeclinePopup()}
          confirm={this.declineOpening}
          jobName={this.props.jobData.name}
          opening={this.state.openDeclinePopup}
        />
      </>
    );
  }
}
ViewJob.contextType = AbilityContext;

function mapStateToProps(store) {
  return {
    cvsLinks: getJobCVSLinks(store),
    user: getUser(store),
    author: getUser(store),
    jobData: getJobData(store),
    openingsList: getOpeningsList(store),
    steps: getJobSteps(store),
    userData: getJobUserData(store),
    candidates: getJobCandidates(store),
    approversCandidate: getJobApprovers(store),
    cvs: getJobCVS(store),
    interview: getJobInterview(store),
    scorecard: getJobScorecard(store),
    offeringCandidate: getJobOffering(store),
    probationPeriod: getJobProbationPeriod(store),
    activityList: store.job.activityList,
    openingCandidate: getOpeningCandidate(store),
    steps_loading: getStepsLoading(store),
    candidateDataLoading: getJobCandidateDataLoading(store),
    rooms: getRooms(store),
    loader: getJobLoader(store),
    hr_managers: getHrManagers(store),
    locations: getLocations(store),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApprovers: bindActionCreators(actions.getApprovers, dispatch),
    getJobInfo: bindActionCreators(actions.getJobInfo, dispatch),
    clearJobInfo: bindActionCreators(actions.clearJobInfo, dispatch),
    deleteJobs: bindActionCreators(actions.deleteJobs, dispatch),
    assignHrToJobs: bindActionCreators(actions.assignHrToJobs, dispatch),
    setLoading: bindActionCreators(setLoading, dispatch),
    getSteps: bindActionCreators(actions.getSteps, dispatch),
    getCandidateStepAssignment: bindActionCreators(actions.getCandidateStepAssignment, dispatch),
    getCandidatesJobs: bindActionCreators(actions.getCandidatesJobs, dispatch),
    getOpenings: bindActionCreators(actions.getOpenings, dispatch),
    setDataOpening: bindActionCreators(actions.setDataOpening, dispatch),
    setApprover: bindActionCreators(actions.setApprover, dispatch),
    deleteOpening: bindActionCreators(actions.deleteOpening, dispatch),
    approveDeclineOpening: bindActionCreators(actions.approveDeclineOpening, dispatch),
    getProbationPassedStartDate: bindActionCreators(actions.getProbationPassedStartDate, dispatch),
    getProbationPassedScorecard: bindActionCreators(actions.getProbationPassedScorecard, dispatch),
    getActivityList: bindActionCreators(actions.getActivityList, dispatch),
    getOpening: bindActionCreators(actions.getOpening, dispatch),
    changeStateJob: bindActionCreators(actions.changeStateJob, dispatch),
    setStepsToJob: bindActionCreators(setStepsToJob, dispatch),
    getRoomsOptions: bindActionCreators(actions.getRoomsOption, dispatch),
    getListHrManagers: bindActionCreators(actions.getListHrManagers, dispatch),
    reassignResponsibleRecruiter: bindActionCreators(actions.reassignResponsibleRecruiter, dispatch),
    reassignRecruiterWithActiveCandidates: bindActionCreators(actions.reassignRecruiterWithActiveCandidates, dispatch),
    getListLocations: bindActionCreators(actions.getListLocations, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewJob);
