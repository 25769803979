import React from 'react';
import { Col, Form, Row } from 'antd';
import { Button, BUTTON_TYPES, ButtonGroup, Checkbox, RichArea, Select, Tooltip, User } from '_atoms';
import { ACTIONS, Can, UI } from 'permission';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { useForm } from 'antd/es/form/Form';
import { DECISION_OPTIONS, ENG_LEVEL_OPTIONS, EXPERIENCE_LEVEL_OPTIONS, REQUIRED_RULE } from './constants';

export const FeedbackCreation = ({ approver, initialValues, onCancel, onFinish }) => {
  const [form] = useForm();

  const onSave = isDraft => {
    form.setFieldValue('is_draft', isDraft);
    form.submit();
  };

  return (
    <div className="feedback-form">
      <User
        className="feedback-form__user"
        avatar={approver.profile.avatar}
        fullName={approver.full_name}
        roles={approver.roles}
      />
      <Form form={form} validateTrigger="onSubmit" initialValues={initialValues} onFinish={onFinish}>
        <Row gutter={[16, 0]}>
          <Col lg={12} md={12} sm={22}>
            <ButtonGroup
              className="feedback-form__decision"
              label="Decision"
              options={DECISION_OPTIONS}
              itemProps={{ name: 'positive', rules: [REQUIRED_RULE] }}
            />
          </Col>
          <Col lg={12} md={12} sm={2}>
            <Tooltip label="This decision is advisory only. If you select “Approve“ or “Reject“, no changes will follow in the system.">
              <Info className="feedback-form__decision-info" />
            </Tooltip>
          </Col>
        </Row>
        <Can I={ACTIONS.UPDATE} a={UI.FEEDBACK_ENGLISH_LEVEL}>
          <Row gutter={[16, 0]}>
            <Col lg={12} md={12} sm={24}>
              <Select
                label="Assumed English level"
                placeholder="Select assumed English level"
                labelInValue
                options={ENG_LEVEL_OPTIONS}
                itemProps={{ name: 'english_level', rules: [REQUIRED_RULE] }}
              />
            </Col>
          </Row>
        </Can>
        <Can I={ACTIONS.UPDATE} a={UI.FEEDBACK_EXPERIENCE_LEVEL}>
          <Row gutter={[16, 0]}>
            <Col lg={12} md={12} sm={24}>
              <Select
                label="Assumed experience level"
                placeholder="Select assumed experience level"
                labelInValue
                options={EXPERIENCE_LEVEL_OPTIONS}
                itemProps={{ name: 'level', rules: [REQUIRED_RULE] }}
              />
            </Col>
          </Row>
        </Can>
        <Row>
          <Col span={24}>
            <Form.Item className="rich-area-item" name="comment" rules={[REQUIRED_RULE]}>
              <RichArea
                initialValue={initialValues.comment_initial}
                label="Comment"
                placeholder="Add a comment"
                maxLength={10000}
              />
            </Form.Item>
          </Col>
        </Row>
        <Checkbox style={{ display: 'none' }} itemProps={{ name: 'is_draft' }} />

        <div className="feedback-form__footer">
          {onCancel && <Button onClick={onCancel}>cancel</Button>}
          <Button type={BUTTON_TYPES.SECONDARY} onClick={() => onSave(true)}>
            save draft
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} onClick={() => onSave(false)}>
            post feedback
          </Button>
        </div>
      </Form>
    </div>
  );
};
