import moment from 'moment';
import { stepsStatuses } from 'constants/constants';
import { STATUSES } from 'constants/user';
import { STATUS_TYPES } from '_atoms/Status';

export const candidatesListMapper = list =>
  list.map(c => ({
    candidate: {
      uuid: c.uuid,
      fullNameEn: c.full_name_latin,
      fullNameRu: c.full_name_local,
    },
    speciality_name: c.speciality_name,
    workflow_assignments: c.workflow_assignments,
    status: c.status,
    job_name_to_hide: c.job_name_to_hide,
  }));

export const getCandidateStatusColor = status => {
  switch (status) {
    case STATUSES.ACTIVE:
    case STATUSES.HIRED:
      return STATUS_TYPES.GREEN;
    case STATUSES.REJECTED:
    case STATUSES.FIRED:
      return STATUS_TYPES.RED;
    case STATUSES.BLACKLISTED:
      return STATUS_TYPES.BLACK;
    default:
      return STATUS_TYPES.GRAY;
  }
};

export const getJobStatusColor = status => {
  switch (status) {
    case stepsStatuses.scheduleInterview:
    case stepsStatuses.receiveFeedback:
    case stepsStatuses.sendOffer:
      return STATUS_TYPES.GRAY;
    case stepsStatuses.attendInterview:
    case stepsStatuses.provideFeedback:
      return STATUS_TYPES.YELLOW;
    case stepsStatuses.waitForResolution:
    case stepsStatuses.waitingForFinalDecision:
      return STATUS_TYPES.BLUE;
    case stepsStatuses.rejected:
      return STATUS_TYPES.RED;
    default:
      return STATUS_TYPES.BLUE;
  }
};

const simpleMapper = array => array.map(({ value }) => value).join(',');
const dateModifier = date => date && moment(date).format('YYYY-MM-DD');

export const filtersMapper = ({ search, ...values }) => ({
  search,
  service: simpleMapper(values.service),
  jobs: simpleMapper(values.jobs),
  job_step: simpleMapper(values.job_step),
  status: simpleMapper(values.status),
  country: simpleMapper(values.country),
  city: values.city && simpleMapper(values.city),
  level: simpleMapper(values.experience_level),
  speciality: simpleMapper(values.speciality),
  tags: simpleMapper(values.tags),
  companies: simpleMapper(values.companies),
  sources: simpleMapper(values.sources),
  responsible_recruiters: simpleMapper(values.responsible_recruiters),
  team_lead: simpleMapper(values.team_lead),
  added_by: simpleMapper(values.added_by),
  interviewed_by: simpleMapper(values.interviewed_by),
  last_interaction_after: dateModifier(values.last_interaction[0]),
  last_interaction_before: dateModifier(values.last_interaction[1]),
  interviewed_date_after: dateModifier(values.interview_date[0]),
  interviewed_date_before: dateModifier(values.interview_date[1]),
  added_during_date_after: dateModifier(values.added_during_date[0]),
  added_during_date_before: dateModifier(values.added_during_date[1]),
});

export const tableOptionsMapper = options => ({
  limit: options.limit.value,
  offset: options.offset,
  sorting_field: options.sorting.field,
  sorting: options.sorting.order,
  only_interacted: options.onlyInteracted,
});
