import React from 'react';
import { Col, Form, Row } from 'antd';
import cn from 'classnames';
import { Button, BUTTON_TYPES, Select } from '_atoms';
import { ReactComponent as Union } from 'dist/icons/Union.svg';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import './style.scss';

export const FormListDoubleSelect = ({
  listName,
  firstSelectProps,
  secondSelectProps,
  firstSelectValidator,
  secondSelectValidator,
  buttonText,
  maxCount = 5,
}) => (
  <Form.List name={listName}>
    {(fields, { add, remove }) => (
      <div className="form-list-double-select">
        {fields.map((field, index) => (
          <Row
            key={field.key}
            className={cn('form-list-double-select__item', { 'with-label': secondSelectProps.label })}
            gutter={{ lg: 32, md: 32, sm: 16 }}
          >
            <Col lg={12} md={12} sm={24} className="form-list-double-select__item__first">
              <Select
                allowClear
                {...firstSelectProps}
                label={!index && firstSelectProps.label}
                labelInValue
                open={
                  firstSelectProps.open
                    ? firstSelectProps.open[`${firstSelectProps.name}_${field.fieldKey}`]
                    : undefined
                }
                onDropdownVisibleChange={open =>
                  firstSelectProps.onDropdownVisibleChange?.(open, `${firstSelectProps.name}_${field.fieldKey}`)
                }
                itemProps={{
                  ...(firstSelectProps.itemProps || {}),
                  ...field,
                  name: [field.name, firstSelectProps.name],
                  fieldKey: [field.fieldKey, firstSelectProps.name],
                  rules: firstSelectValidator
                    ? [...(firstSelectProps.itemProps?.rules || []), f => firstSelectValidator(f, field.name)]
                    : undefined,
                }}
              />
            </Col>
            <Col lg={12} md={12} sm={24} className="form-list-double-select__item__second">
              <div>
                <Row align="middle" gutter={{ lg: 20, md: 20, sm: 0 }}>
                  <Col flex="1 1 0">
                    <Select
                      allowClear
                      {...secondSelectProps}
                      label={!index && secondSelectProps.label}
                      labelInValue
                      open={
                        secondSelectProps.open
                          ? secondSelectProps.open[`${secondSelectProps.name}_${field.fieldKey}`]
                          : undefined
                      }
                      onDropdownVisibleChange={open =>
                        secondSelectProps.onDropdownVisibleChange?.(open, `${secondSelectProps.name}_${field.fieldKey}`)
                      }
                      itemProps={{
                        ...(secondSelectProps.itemProps || {}),
                        ...field,
                        name: [field.name, secondSelectProps.name],
                        fieldKey: [field.fieldKey, secondSelectProps.name],
                        rules: secondSelectValidator
                          ? [...(secondSelectProps.itemProps?.rules || []), f => secondSelectValidator(f, field.name)]
                          : undefined,
                      }}
                    />
                  </Col>
                  {!!index && (
                    <Col>
                      <Button
                        className="form-list-double-select__item__delete"
                        type={BUTTON_TYPES.GHOST}
                        onClick={() => remove(field.name)}
                        icon={<Bin />}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        ))}
        {fields.length < maxCount && (
          <Button icon={<Union />} onClick={() => add()}>
            {buttonText}
          </Button>
        )}
      </div>
    )}
  </Form.List>
);
