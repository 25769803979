import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Form } from 'antd';
import * as actions from 'actions';
import { notification, NOTIFICATION_TYPES, Popup } from '_atoms';
import { FormCreateEdit, PopupCandidateDuplicate } from '_molecules';
import { CandidateCreationForm } from '_organisms';
import history from 'helpers/history';
import { MixpanelService } from 'helpers/Mixpanel';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { levels } from 'constants/constants';
import { ROUTES } from 'router/constants';
import { clearCandidateData, setDuplicateCandidates } from 'store/reducers/candidateReducer';
import { EMPTY_CANDIDATE_DATA, SCROLL_BAR_CANDIDATE } from './constants';
import { formDataMapper } from '../../_organisms/CandidateCreationForm/utyls';
import { BreadCrumbs } from '../../_atoms/BreadCrumbs';
import { uploadDocs } from './utils';
import './style.scss';

const CandidatesCreation = props => {
  const dispatch = useDispatch();
  const { id: candidateId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDuplicatePopup, setOpenDuplicatePopup] = useState(false);
  const [duplicate, setDuplicate] = useState({});

  useEffect(() => {
    props.getForeignLanguages();
    props.getCountries();
    props.getCompanies({});
    props.getListLocations();
    props.getPositions();
    props.getTags();
    props.getSpecialties();
    const mixpanel = new MixpanelService();
    if (candidateId) {
      mixpanel.track('View Page.Edit Candidate', {
        'Candidate ID': candidateId,
      });
    } else {
      mixpanel.track('View Page.Create Candidate');
    }
    return () => props.clearCandidateData();
  }, []);

  const saveCandidate = async values => {
    setLoading(true);
    dispatch(setDuplicateCandidates([]));
    const data = formDataMapper(values);
    data.cvs = await uploadDocs(data.cvs);
    props.saveCandidate(data, candidateId, props.isSSC).then(res => {
      notification({
        message: `Candidate profile has been ${candidateId ? 'updated' : 'created'}.`,
        type: NOTIFICATION_TYPES.SUCCESS,
      });
      setLoading(false);
      history.push(`/${props.isSSC ? 'self-submitted-' : ''}candidates/${res.uuid}/show`);
    });
  };

  const goBack = () => {
    const { description, ...touchedValues } = form.getFieldsValue(null, meta => meta.touched);
    if (description !== form.getFieldValue('description_initial') || Object.keys(touchedValues).length) {
      setOpenConfirmation(true);
    } else {
      onOkConfirmation();
    }
  };

  const onCloseConfirmation = () => {
    setOpenConfirmation(false);
    dispatch(setDuplicateCandidates([]));
  };

  const onOkConfirmation = () => {
    if (props.isSSC) {
      history.push(`/self-submitted-candidates/${candidateId}/show`);
    } else if (candidateId) {
      history.push(`/candidates/${candidateId}/show`);
    } else {
      history.push(ROUTES.CANDIDATES);
    }
  };

  const onOpenDuplicatesPopup = data => {
    setDuplicate(data);
    setOpenDuplicatePopup(true);
  };
  const onCloseDuplicationPopup = () => {
    setOpenDuplicatePopup(false);
    setDuplicate({});
  };

  const onValueChange = (
    { experience: exp, specialities: spec, country, state, city },
    { experience, specialities }
  ) => {
    if (exp) {
      form.setFieldValue(
        'experience',
        experience.map((el, idx) => {
          const isFrom = !!exp[idx]?.worked_from;
          const isTill = !!exp[idx]?.worked_till;
          const worked_from = exp[idx]?.worked_from || el?.worked_from || moment();
          const worked_till = exp[idx]?.worked_till || el?.worked_till || moment();
          return {
            ...el,
            worked_from: isTill && worked_from.isAfter(worked_till) ? worked_till : worked_from,
            worked_till: isFrom && worked_till.isBefore(worked_from) ? worked_from : worked_till,
            company: el?.company?.slice(-1).map(c => ({ ...c, label: c.label || c.value })),
            position: el?.position?.slice(-1),
          };
        })
      );
    }
    if (spec) {
      form.setFieldValue(
        'specialities',
        specialities.map(s =>
          s
            ? { level: s.level, speciality: s.speciality.slice(-1) }
            : { speciality: undefined, level: { value: levels[0], label: levels[0] } }
        )
      );
    }
    if (country) {
      form.setFieldValue('state', undefined);
      form.setFieldValue('city', []);
    }
    if (state) {
      form.setFieldValue('city', []);
    }
    if (city) {
      form.setFieldValue('city', city.slice(-1));
    }
  };

  const onCheckDuplicates = useCallback(() => {
    const { first_name_latin, last_name_latin, first_name_local, last_name_local, phone, email, skype } =
      form.getFieldsValue();
    const full_name_latin = `${first_name_latin || ''} ${last_name_latin || ''}`.trim();
    const full_name_local = `${first_name_local || ''} ${last_name_local || ''}`.trim();
    const duplicateData = { full_name_latin, full_name_local, phone, email, skype };
    props
      .checkDuplicate({
        full_name_latin,
        full_name_local,
        phone: phone.map(encodeURIComponent),
        email: email.map(encodeURIComponent),
        skype: encodeURIComponent(skype),
        exclude_uuid: form.getFieldValue('uuid'),
      })
      .then(resp => {
        if (resp.length) {
          notification({
            key: 'duplicates',
            type: NOTIFICATION_TYPES.WARNING,
            message: `Found ${resp.length} duplicates.`,
            btn: (
              <div role="none" onClick={() => onOpenDuplicatesPopup(duplicateData)}>
                Open Duplicates
              </div>
            ),
          });
        }
      });
  }, [form]);

  return (
    <div className="wrapper-content candidates-creation-page">
      <BreadCrumbs
        element="Candidates"
        elementName={candidateId ? 'Edit candidate' : 'Candidate creation'}
        path="/candidates"
      />
      <Form
        form={form}
        onFinish={saveCandidate}
        initialValues={EMPTY_CANDIDATE_DATA}
        validateTrigger="onSubmit"
        scrollToFirstError
        onValuesChange={onValueChange}
      >
        <FormCreateEdit
          contentClassName="form-candidate-creation"
          title={candidateId ? 'Edit candidate' : 'Candidate creation'}
          options={SCROLL_BAR_CANDIDATE}
          onCancel={goBack}
          okText={candidateId ? 'Save changes' : 'Create Candidate'}
          cancelText="Cancel"
          loading={loading}
        >
          <CandidateCreationForm
            form={form}
            onCheckDuplicates={onCheckDuplicates}
            isSSC={props.isSSC}
            setLoading={setLoading}
          />
        </FormCreateEdit>
        <Popup
          open={openConfirmation}
          title={POPUP_LABELS.DISCARD_CHANGES}
          cancelButtonProps={{ onClick: onCloseConfirmation, children: BUTTON_TEXT.BACK_TO_EDIT }}
          okButtonProps={{ onClick: onOkConfirmation, children: BUTTON_TEXT.DISCARD }}
        >
          {POPUP_BODY.CHANGES_NOT_SAVED}
        </Popup>
        <PopupCandidateDuplicate
          open={openDuplicatePopup}
          onClose={onCloseDuplicationPopup}
          candidateDuplicates={props.candidateDuplicates}
          duplicate={duplicate}
        />
      </Form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    candidateDuplicates: state.candidate.candidateDuplicates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getForeignLanguages: bindActionCreators(actions.getForeignLanguages, dispatch),
    getCountries: bindActionCreators(actions.getCountries, dispatch),
    getSpecialties: bindActionCreators(actions.getSpecialties, dispatch),
    getCompanies: bindActionCreators(actions.getCompanies, dispatch),
    getListLocations: bindActionCreators(actions.getListLocations, dispatch),
    getPositions: bindActionCreators(actions.getPositions, dispatch),
    getTags: bindActionCreators(actions.getTags, dispatch),
    saveCandidate: bindActionCreators(actions.saveCandidate, dispatch),
    clearCandidateData: bindActionCreators(clearCandidateData, dispatch),
    checkDuplicate: bindActionCreators(actions.checkDuplicate, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesCreation);
