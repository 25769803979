import moment from 'moment';
import { levels, SOCIAL_NETWORKS } from 'constants/constants';

export const dataMapper = data =>
  Object.entries({
    uuid: data.uuid,
    first_name_latin: data.first_name_latin,
    last_name_latin: data.last_name_latin,
    first_name_local: data.first_name_local,
    last_name_local: data.last_name_local,
    country: data.country ? { value: data.country, label: data.country } : undefined,
    state: data.state ? { value: data.state, label: data.state } : undefined,
    city: data.city ? [{ value: data.city, label: data.city }] : [],
    offices: data.offices.map(o => ({ value: o.uuid, label: o.name })),
    sources: data.sources,
    timezone: data.timezone ? { value: data.timezone, label: data.timezone } : undefined,
    description_initial: data.description,
    description: data.description,
    foreign_languages: data.foreign_languages.length
      ? data.foreign_languages.map(lang => ({
          language: { value: lang.language, label: lang.language },
          level: { value: lang.level, label: lang.level },
        }))
      : [{ language: undefined, level: undefined }],
    is_ready_to_relocate: data.is_ready_to_relocate,
    is_ready_to_business_trips: data.is_ready_to_business_trips,
    phone: data.phone.length ? data.phone : [''],
    email: data.email.length ? data.email : [''],
    telegram: data.telegram[0],
    skype: data.skype[0],
    social_networks: data.social_networks.length ? data.social_networks : [{ link: '', network: undefined }],
    cvs: [],
    cv_link_0: data.cvs_links[0],
    cv_link_1: data.cvs_links[1],
    tags: data.tags.map(t => ({ value: t, label: t })),
    specialities: data.specialities.length
      ? data.specialities.map(s => ({
          speciality: s.speciality ? [{ value: s.speciality, label: s.speciality }] : undefined,
          level: { value: s.level, label: s.level },
        }))
      : [{ speciality: undefined, level: { value: levels[0], label: levels[0] } }],
    experience: data.experience.length
      ? data.experience.map(ex => ({
          company: ex.company ? [{ value: ex.company, label: ex.company }] : [],
          position: ex.position ? [{ value: ex.position, label: ex.position }] : [],
          worked_from: ex.worked_from ? moment(ex.worked_from, 'YYYY-MM-DD') : null,
          worked_till: ex.worked_till ? moment(ex.worked_till, 'YYYY-MM-DD') : null,
          is_current: ex.is_current,
          additional_info: ex.additional_info,
          additional_info_initial: ex.additional_info,
        }))
      : [
          {
            company: undefined,
            position: undefined,
            worked_from: moment(),
            worked_till: moment(),
            is_current: false,
            additional_info: '',
            additional_info_initial: '',
          },
        ],
    resume_link: data.resume_links[0],
  }).map(([name, value]) => ({ name, value }));

export const formDataMapper = data => ({
  first_name_latin: data.first_name_latin,
  last_name_latin: data.last_name_latin,
  first_name_local: data.first_name_local,
  last_name_local: data.last_name_local,
  country: data.country?.value || '',
  state: data.state?.value || '',
  city: data.city[0]?.value || '',
  offices: data.offices.map(el => el.value),
  sources: data.sources,
  timezone: data.timezone?.value || '',
  description: data.description,
  foreign_languages: data.foreign_languages
    .filter(lang => lang.language?.value && lang.level?.value)
    .map(lang => ({ language: lang.language?.value, level: lang.level?.value })),
  is_ready_to_relocate: data.is_ready_to_relocate,
  is_ready_to_business_trips: data.is_ready_to_business_trips,
  phone: data.phone.filter(el => el),
  email: data.email.filter(el => el),
  telegram: data.telegram ? [data.telegram] : [],
  skype: data.skype ? [data.skype] : [],
  social_networks: data.social_networks
    .filter(n => n?.link)
    .map(n => ({
      link: !n.link.includes('http://') && !n.link.includes('https://') ? `https://${n.link}` : n.link,
      network: n.network,
    })),
  cvs: data.cvs ? data.cvs.map(cv => (cv.uuid ? cv.uuid : cv)) : [],
  cvs_links: [data.cv_link_0, data.cv_link_1].filter(el => !!el),
  tags: data.tags.map(el => el.label || el.value),
  specialities: data.specialities.map(s => ({ speciality: s.speciality?.[0]?.value || null, level: s.level.value })),
  experience: data.experience
    .filter(ex => ex.company?.length && ex.position?.length)
    .map(ex => ({
      company: ex.company[0].label,
      position: ex.position[0].value,
      worked_from: ex.worked_from ? ex.worked_from.format('YYYY-MM-DD') : null,
      worked_till: ex.worked_till ? ex.worked_till.format('YYYY-MM-DD') : null,
      is_current: ex.is_current,
      additional_info: ex.additional_info,
    })),
  resume_links: data.resume_link ? [data.resume_link] : [],
});

export const detectNetwork = link => {
  if (link.includes('linkedin.com')) return SOCIAL_NETWORKS.LINKEDIN;
  if (link.includes('www.facebook.com')) return SOCIAL_NETWORKS.FACEBOOK;
  if (link.includes('twitter.com')) return SOCIAL_NETWORKS.TWITTER;
  if (link.includes('stackoverflow.com')) return SOCIAL_NETWORKS.STACK;
  if (link.includes('gitlab')) return SOCIAL_NETWORKS.GITLAB;
  if (link.includes('vk.com')) return SOCIAL_NETWORKS.VK;
  if (link.includes('instagram.com')) return SOCIAL_NETWORKS.INSTAGRAM;
  if (link.includes('dribbble.com')) return SOCIAL_NETWORKS.DRIBBBLE;
  if (link.includes('www.behance.net')) return SOCIAL_NETWORKS.BEHANCE;
  return SOCIAL_NETWORKS.LINK;
};

export const deepEqual = (x, y) => {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
    : x === y;
};
