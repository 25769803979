import moment from 'moment';

export const SCROLL_BAR_CANDIDATE = [
  { title: 'Parsing', id: 'parsing' },
  { title: 'Profile', id: 'profile' },
  { title: 'Contact information', id: 'contact_information' },
  { title: 'Experience', id: 'experience' },
  { title: 'Working experience', id: 'working_experience' },
];

export const EMPTY_CANDIDATE_DATA = {
  '': undefined,
  first_name_latin: '',
  last_name_latin: '',
  first_name_local: '',
  last_name_local: '',
  country: undefined,
  city: [],
  cv_link_0: '',
  cv_link_1: '',
  cvs: undefined,
  offices: [],
  sources: [{ source: undefined, is_main: true }],
  timezone: undefined,
  description: '',
  description_initial: '',
  foreign_languages: [{ language: undefined, level: undefined }],
  is_ready_to_relocate: false,
  is_ready_to_business_trips: false,
  phone: [''],
  email: [''],
  telegram: '',
  skype: '',
  social_networks: [{ link: '', network: undefined }],
  specialities: [{ language: undefined, level: { value: 'No Level', label: 'No Level' } }],
  tags: [],
  experience: [
    {
      company: undefined,
      position: undefined,
      is_current: false,
      worked_from: moment(),
      worked_till: moment(),
      additional_info: '',
    },
  ],
};
