import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { Button, BUTTON_TYPES, Checkbox, Table } from '_atoms';
import { Typography } from '_atoms/Typography';
import { TableFooter, TableHeader } from '_molecules';
import { FiltersCandidate } from '_organisms';
import { MixpanelService } from 'helpers/Mixpanel';
import history from 'helpers/history';
import { getListCandidates } from 'actions';
import { changeCandidateFilter } from 'store/reducers/candidateReducer';
import { getCandidateListFilters } from 'store/selectors/candidateSelectors';
import { ACTIONS, Can, SUBJECTS, UI, useAbility } from 'permission';
import { BUTTON_TEXT } from 'constants/text';
import { ROUTES } from 'router/constants';
import { candidatesListMapper, filtersMapper, tableOptionsMapper } from './utils';
import { COLUMNS, TEXT, INITIAL_VALUES, INITIAL_TABLE_VALUES, TableEmpty, CANDIDATE_SORT_OPTIONS } from './constants';
import 'styles/mainWrapperContentApp.scss';
import './style.scss';

export const CandidateList = () => {
  const ability = useAbility();
  const dispatch = useDispatch();

  const defaultFilters = useSelector(getCandidateListFilters);

  const [form] = Form.useForm();
  const initialFilters = useMemo(() => ({ ...INITIAL_VALUES, ...defaultFilters }), []);
  const [loading, setLoading] = useState(false);
  const [tableOptions, setTableOptions] = useState(INITIAL_TABLE_VALUES);
  const [candidatesList, setCandidatesList] = useState([]);
  const [expandedColumns, setExpandedColumns] = useState({});

  const hasMenu =
    ability.can(ACTIONS.READ, UI.ONLY_INTERACTED) &&
    (tableOptions.onlyInteracted || !!form.getFieldValue('last_interaction')?.filter(v => !!v).length);

  const onCreateCandidate = () => history.push(ROUTES.CANDIDATES_CREATE);

  const handleChangeDate = (field, item) => {
    setTableOptions(s => ({ ...s, [field]: item }));
    if (field === 'page') {
      setTableOptions(s => ({ ...s, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, offset: 0, page: 1 }));
    }
  };

  const getCandidates = (params = form.getFieldsValue()) => {
    dispatch(changeCandidateFilter(params));
    setLoading(true);
    getListCandidates({ ...filtersMapper(params), ...tableOptionsMapper(tableOptions) }, 'v2')
      .then(resp => {
        setTableOptions(s => ({ ...s, itemsCount: resp.count }));
        setCandidatesList(candidatesListMapper(resp.results));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const setSorting = sort => handleChangeDate('sorting', sort);
  const setInteracted = interacted => {
    handleChangeDate('onlyInteracted', interacted);
    if (
      !interacted &&
      (tableOptions.sorting.order === CANDIDATE_SORT_OPTIONS[2].order ||
        tableOptions.sorting.order === CANDIDATE_SORT_OPTIONS[3].order)
    ) {
      handleChangeDate('sorting', INITIAL_TABLE_VALUES.sorting);
    }
  };

  const applyFilters = () => {
    if (tableOptions.offset === 0 && tableOptions.page === 1) {
      getCandidates();
    } else {
      setTableOptions(s => ({ ...s, offset: 0, page: 1 }));
    }
  };

  const resetFilter = () => {
    form.setFields(Object.entries(INITIAL_VALUES).map(([name, value]) => ({ name, value })));
    dispatch(changeCandidateFilter({}));
    applyFilters();
  };

  useEffect(getCandidates, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.onlyInteracted,
  ]);

  const extra = (
    <div className="table-candidates__extra">
      <Can I={ACTIONS.READ} a={UI.ONLY_INTERACTED}>
        <Checkbox
          label="Only interacted"
          isSelected={tableOptions.onlyInteracted}
          onChange={e => setInteracted(e.target.checked)}
        />
      </Can>
      <Can I={ACTIONS.CREATE} a={SUBJECTS.CANDIDATE}>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={onCreateCandidate}>
          {BUTTON_TEXT.ADD_CANDIDATE}
        </Button>
      </Can>
    </div>
  );

  useEffect(() => {
    const mixpanel = new MixpanelService();
    mixpanel.track('View Page.Candidates List');
  }, []);

  return (
    <div className="wrapper-content candidate-list-page">
      <FiltersCandidate
        form={form}
        initialValues={initialFilters}
        onFinish={applyFilters}
        onReset={resetFilter}
        loading={loading}
      />
      <Table
        rowKey="uuid"
        className="table-candidates"
        cardTitle={TEXT.TITLE}
        cardCount={tableOptions.itemsCount}
        emptyComponent={<TableEmpty />}
        extra={extra}
        columns={COLUMNS(tableOptions.sorting, setSorting, hasMenu, expandedColumns, setExpandedColumns)}
        loading={loading}
        data={candidatesList}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        showHeader={candidatesList.length}
        showCardFooter={candidatesList.length}
        updateParams={handleChangeDate}
      />
      <CandidatesTableMobile
        cardTitle={TEXT.TITLE}
        cardCount={tableOptions.itemsCount}
        emptyComponent={<TableEmpty />}
        extra={extra}
        columns={COLUMNS(tableOptions.sorting, setSorting, hasMenu, expandedColumns, setExpandedColumns)}
        loading={loading}
        data={candidatesList}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        showHeader={candidatesList.length}
        showCardFooter={candidatesList.length}
        updateParams={handleChangeDate}
      />
    </div>
  );
};

const CandidatesTableMobile = ({ data, columns, ...props }) => (
  <div className="table-candidates_mobile">
    <TableHeader
      title={props.cardTitle}
      count={props.cardCount}
      tooltipInfo={props.tooltipInfo}
      loading={props.loading}
      sorting={props.timeSorting}
      onSorting={props.onTimeSorting}
      extra={props.extra}
      newLoading
    />
    {data.map(d => (
      <div className="candidate-item">
        {columns.map(
          (c, idx) =>
            idx !== 3 && (
              <div className="candidate-item__row">
                <Typography.Text className="title">{c.title}</Typography.Text>
                <div className="content">
                  {c.render?.(d[c.dataIndex], { ...d, isMobile: true }) || d[c.rowKey] || '-'}
                </div>
              </div>
            )
        )}
      </div>
    ))}
    {!data.length && <TableEmpty />}
    <TableFooter
      limit={props.limit}
      page={props.page}
      onChangePage={props.updatePage}
      onChangeLimit={props.updateLimit}
      itemsCount={props.itemsCount}
      loading={props.loading}
      newLoading
    />
  </div>
);
